<template>
  <v-container>
    <v-row>
      <v-col cols="8">
        <h2>
          <v-icon class="pr-5" color="green">dashboard</v-icon>
          Dashboard
        </h2>
      </v-col>
      <v-col cols="4">
        <PeriodSelector @period-changed="periodChanged"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-alert :value="!devices" color="info" icon="info" outlined>
          Hey, je hebt nog geen sensoren geregistreerd..
        </v-alert>
      </v-col>
    </v-row>
    <v-row :key="device.id" row v-for="device in devices">
      <v-col>
        <device-card
          v-if="device.type === 'TEMPERATURE'"
          :device="device"
          :since="since"
          :key="since.toMillis()"
        />
        <device-map-card
          v-if="device.type === 'GPS_TRACKER'"
          :device="device"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DeviceCard from '@/components/DeviceCard'
import DeviceMapCard from '@/components/DeviceMapCard'
import PeriodSelector from '@/components/PeriodSelector'

export default {
  components: {
    DeviceCard,
    DeviceMapCard,
    PeriodSelector
  },
  data () {
    return {
      hours: 6,
      since: this.$dateTime.now().minus({ hours: 3 }),
    }
  },
  computed: {
    devices () {
      return this.$store.getters['deviceStore/devices']
    },
  },
  methods: {
    periodChanged (period) {
      this.hours = period.hours
      this.since = this.$dateTime.now().minus({ hours: period.hours })
    }
  }
}
</script>
