<template>
  <div>
    <div id="g-map"></div>
  </div>
</template>
<script>
import { Loader } from '@googlemaps/js-api-loader'

export default {
  props: ['coordinates'],
  data () {
    return {
      map: null,
    }
  },
  methods: {
    initMap () {
      new Loader({
        apiKey: 'AIzaSyCvbpdHjpDgOeaq4M6XWQ7HPYMz_emJ5uY',
        version: 'weekly',
      }).load().then(() => {
        const gpsCoordinates = { lat: this.coordinates.latitude, lng: this.coordinates.longitude };
        // eslint-disable-next-line no-undef
        new google.maps.Marker({
          position: gpsCoordinates,
          // eslint-disable-next-line no-undef
          map: new google.maps.Map(document.getElementById('g-map'), {
            center: gpsCoordinates,
            zoom: 14,
          }),
        })
      })
    }
  },
  mounted () {
    if (this.coordinates) this.initMap()
  }
}
</script>
<style lang="scss">
#g-map {
  min-height: 300px;
  background-color: azure;
}
</style>
