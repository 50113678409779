<template>
  <div>
    <v-alert
      rounded="0"
      border="bottom"
      type="warning"
      colored-border
      color="orange accent-4"
      elevation="2"
    >
      <p>Let op: <strong>{{ device.name }}</strong> is momenteel in <strong>ALARM</strong> modus. Klik op <strong>Reset alarm</strong> om het apparaat weer in normale modus te zetten.</p>
      <p>Zolang het apparaat in <strong>ALARM</strong> modus staat krijg je geen nieuwe meldingen als de alarmwaarde (<strong>{{ device.alarmValue }} graden</strong>) wordt bereikt.</p>
      <v-btn
        color="primary"
        @click="resetToActiveState(device)"
      >
        <v-icon left>check</v-icon>
        Reset alarm
      </v-btn>
    </v-alert>
  </div>
</template>
<script>
export default {
  props: ["device"],
  methods: {
    resetToActiveState(device) {
      this.$store.dispatch('deviceStore/resetToActiveState', device)
    }
  }
}
</script>
<style>

</style>
