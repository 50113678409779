<template>
  <v-card hover>
    <v-card-title>
      <v-icon>speaker_phone</v-icon>
      {{ device.name }}
    </v-card-title>
    <v-card-subtitle>
      <v-row class="blue-grey--text">
        <v-col cols="4">
          <strong>DevEUI: </strong>{{ device.devEui }} <br>
        </v-col>
        <v-col cols="4">
          <strong>Laatste update: </strong>
          <span v-if="uplink.createdAt">
            {{ this.$dateTime.fromISO(uplink.createdAt).toRelative() }}
          </span>
          <span v-else>nog nooit</span>
        </v-col>
        <v-col>
          In beweging: <strong>{{ movingMode.isMoving }}</strong>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-card-text>
      <v-sheet class="pa-3 lighten-5" color="blue" v-if="warning">{{ warning }}</v-sheet>
      <map-component :coordinates="coordinates" v-if="Object.keys(coordinates).length > 0"/>

      <div class="d-flex flex-row justify-space-around">
        <v-sheet :key="uplink.id" class="justify-content">
          <h5>{{ uplink.key }}</h5>
          <p class="text-center">{{ uplink.value }}</p>
        </v-sheet>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import DevicesApi from '@/api/DevicesApi'
import MapComponent from '@/components/Map'

export default {
  components: {
    MapComponent
  },
  props: ['device', 'since'],
  data: () => ({
    warning: null,
    uplink: {},
  }),
  computed: {
    coordinates () {
      const coordinates = {}
      if (!Array.isArray(this.uplink.values) || this.uplink.values.length === 0) return coordinates
      for (const uplinkValue of this.uplink.values) {
        coordinates[uplinkValue.key] = uplinkValue.value
      }
      return coordinates
    },
    movingMode() {
      if (!Array.isArray(this.uplink.values)) return { isMoving: 'unknown'}
      return this.uplink.values
        .filter(uplinkValue => uplinkValue.key === 'movingMode')
        .map(uplinkValue => ( {
          isMoving: uplinkValue.value ? 'Ja' : 'Nee'
        }))[0]
    }
  },
  methods: {
    loadDeviceData () {
      DevicesApi.lastUplink(this.device.id).then(uplink => {
        this.uplink = uplink
      }).catch(reason => this.warning = `Kan geen data vinden (${reason})`)
    },
  },
  mounted () {
    this.loadDeviceData()
  },
}
</script>
