<template>
  <div class="PeriodSelector">
    <v-select
      :items="periods.values"
      label="Toon afgelopen"
      v-model="periods.selected"
      @change="$emit('period-changed', {hours: periods.selected})"
    >
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'Periodselector',
  data: () => ({
    periods: {
      selected: 3,
      values: [
        { value: 3, text: '3 uur' },
        { value: 6, text: '6 uur' },
        { value: 12, text: '12 uur' },
        { value: 24, text: '24 uur' },
      ],
    },
  }),
}
</script>
