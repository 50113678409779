<template>
  <v-card hover>
    <v-card-title>
      <v-row>
        <v-col cols="9">
          <v-icon>speaker_phone</v-icon>
          {{ device.name }}
        </v-col>
        <v-col class="text-right last-measurement-value">
          {{ getLastMeasurementForDeviceType(this.lastUplink, this.device.type) }}
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-subtitle>
      <v-row class="blue-grey--text">
        <v-col cols="6">
          <strong>DevEUI: </strong>{{ device.devEui }} <br>
        </v-col>
        <v-col cols="6">
          <strong>Laatste update van: </strong>
          <span v-if="lastUplink.createdAt">
            {{ this.$dateTime.fromISO(lastUplink.createdAt).toRelative() }}
          </span>
          <span v-else>nog nooit</span>
        </v-col>
      </v-row>
    </v-card-subtitle>
      <device-alarmed-resolver v-if="device.status === 'ALARMED'" :device="device"/>
    <v-card-text>
      <v-sheet class="pa-3 lighten-5" color="blue" v-if="warning">{{ warning }}</v-sheet>
      <chart-component :data="uplinkData" :device="device"/>

      <div class="d-flex flex-row justify-space-around">
        <v-sheet :key="uplink.id" class="justify-content" v-for="uplink in uplinks">
          <h5>{{ uplink.key }}</h5>
          <p class="text-center">{{ uplink.value }}</p>
        </v-sheet>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import ChartComponent from '@/components/Chart'
import DevicesApi from '@/api/DevicesApi'
import DeviceAlarmedResolver from '@/components/DeviceAlarmedResolver'

export default {
  components: {
    ChartComponent,
    DeviceAlarmedResolver,
  },
  props: ['device', 'since'],
  data: () => ({
    warning: null,
    uplinks: [],
    lastUplink: {}
  }),
  computed: {
    uplinkData () {
      let results = {}
      this.uplinks.forEach(uplink => {
        uplink.values.forEach(uplinkValue => {
          if (!results[uplinkValue.key]) {
            results[uplinkValue.key] = []
          }
          const createdAtDateTime = this.$dateTime.fromISO(uplink.createdAt)
          if (createdAtDateTime < this.since) {
            return
          }
          results[uplinkValue.key].push({ x: createdAtDateTime, y: parseFloat(uplinkValue.value).toFixed(1) })
        })
      })
      return results
    }
  },
  methods: {
    loadDeviceData () {
      DevicesApi.dataForDeviceId(this.device.id, this.since.toFormat('yyyy-MM-dd')).then(uplinks => {
        if (!Array.isArray(uplinks) || uplinks.length === 0) {
          this.warning = `Geen metingen gevonden`
          return
        }
        this.uplinks = uplinks
      }).catch(reason => this.warning = `Geen metingen gevonden (${reason})`)
    },
    loadLastUplink () {
      DevicesApi.lastUplink(this.device.id).then(uplink => this.lastUplink = uplink)
        .catch(reason => this.warning = `Geen metingen gevonden voor ${this.since} en later. ( reden: ${reason})`)
    },
    getLastMeasurementForDeviceType (uplink, deviceType) {
      if (!uplink.values) return null
      // uplink will contains values with key => value
      let measurement = uplink.values.map(uplinkValue => {
        // if device is TEMPERATURE there should be a temperature key
        if (uplinkValue.key === deviceType.toLowerCase()) return uplinkValue.value
        return null
      })
      // it can be we have a empty measurement for device type
      if (measurement.length > 0) return measurement[0].toFixed(1) + '°'
      return null
    }
  },
  mounted () {
    this.loadDeviceData()
    this.loadLastUplink()
  },
}
</script>
<style lang="scss">
.last-measurement-value {
  font-size: 1.20em;
}
</style>
