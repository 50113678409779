<template>
  <div>
    <line-chart
      :data="chartData"
      :height="250"
      :options="chartOptions"
      class="chart-panel"
      v-if="chartData.datasets.length > 0"
    />
  </div>
</template>
<script>
import LineChart from '@/charts/Line.js'

export default {
  components: {
    LineChart,
  },
  props: ['device', 'data'],
  data: () => ({
    chartOptions: {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 25,
          bottom: 20,
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              precision: 0.1,
            },
          },
        ],
        xAxes: [
          {
            type: 'time',
            gridLines: {
              display: false,
            },
            time: {
              round: 'minute',
              unit: 'minute',
              stepSize: 15,
              displayFormats: {
                minute: 'HH:mm',
              },
              tooltipFormat: 'HH:mm',
            },
          },
        ],
      },
      tooltips: {
        mode: 'nearest',
        intersect: false,
        callbacks: {
          title: (tooltipArr) => {
            return `Tijdstip ${tooltipArr[0].label}`
          },
          label: function (tooltipItem) {
            return `Om ${tooltipItem.label} uur was het ${tooltipItem.value} graden`
          },
        },
      },
    },
  }),
  computed: {
    chartData () {
      if (Object.keys(this.data).length === 0) return { datasets: []}
      let datasets = []
      for (const [key, uplinkDataArray] of Object.entries(this.data)) {
        datasets.push({
          label: key,
          data: uplinkDataArray,
          borderColor: 'rgba(104, 195, 163, 1)',
          backgroundColor: 'rgba(30, 130, 76, 1)',
          fill: true
        })
      }
      return {
        datasets: datasets
      }
    }
  },
}
</script>
